<template>
  <div class="flex min-h-screen flex-col lg:flex-row font-fakt-medium">
    <!-- Left side (login form) -->
    <div class="flex flex-1 flex-col justify-center px-4 py-6 sm:px-6 lg:px-8 xl:px-12">
      <div class="mx-auto w-full max-w-sm sm:max-w-md">
        <img class="w-24 sm:w-32 lg:w-44 -mb-2 sm:-mb-4 lg:-mb-8 -mt-2 sm:-mt-4 lg:-mt-8 -ml-1 sm:-ml-2 lg:-ml-4" src="/assets/wobby-logo/logo_purple.svg" alt="Wobby" />

        <div v-if="showSignIn" class="mt-6 sm:mt-8 lg:mt-10">
          <h2 class="text-lg sm:text-xl lg:text-2xl font-fakt-medium leading-tight tracking-tight text-gray-900">Sign in</h2>
          <p class="mb-2 sm:mb-3 text-xs sm:text-sm font-fakt-blond leading-5 sm:leading-6 text-gray-500">
            Not a member?
            <a href="/auth/register" class="font-semibold text-wobby-purple-600 hover:underline hover:text-wobby-purple-500 font-fakt-blond">Start 10-day free trial</a>
          </p>
          
          <!-- Form fields (adjust sizing) -->
          <form action="#" method="POST" class="space-y-3 sm:space-y-4">
            <!-- Email input -->
            <div>
              <label for="email" class="block text-xs sm:text-sm font-fakt-medium leading-5 sm:leading-6 text-gray-900">Email address</label>
              <input id="email" v-model="loginForm.email" type="email" required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xs sm:text-sm" />
            </div>

            <!-- Password input -->
            <div>
              <label for="password" class="block text-xs sm:text-sm font-fakt-medium leading-5 sm:leading-6 text-gray-900">Password</label>
              <input id="password" v-model="loginForm.password" type="password" required @keydown.enter="submit"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xs sm:text-sm" />
            </div>

            <!-- Remember me and Forgot password -->
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input id="remember-me" v-model="loginForm.keepSignedIn" type="checkbox"
                  class="h-3 w-3 sm:h-4 sm:w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                <label for="remember-me" class="ml-2 block text-xs sm:text-sm text-gray-900">Remember me</label>
              </div>
              <div class="text-xs sm:text-sm">
                <a @click="showForgotPassword = true" class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">Forgot password?</a>
              </div>
            </div>

            <!-- Sign in button -->
            <div>
              <UButton color="indigo" variant="solid" class="w-full justify-center rounded-md text-sm sm:text-base py-2 sm:py-2.5" 
                @click.prevent="submit" :loading="loading" id="signIn">Sign in</UButton>
            </div>
          </form>

          <!-- Error message -->
          <div v-if="error" class="mt-3 sm:mt-4 text-red-600 text-xs sm:text-sm">{{ error }}</div>

          <!-- Terms of Service -->
          <p class="mt-3 sm:mt-4 text-xs text-gray-500">
            By signing in, you agree to our
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Terms of Service</a> and
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Privacy Policy</a>.
          </p>

          <!-- Social login buttons -->
          <div class="mt-4 sm:mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-xs sm:text-sm">
                <span class="bg-white px-2 text-gray-500">Or continue with</span>
              </div>
            </div>

            <div class="mt-4 sm:mt-6 grid grid-cols-1 gap-2 sm:gap-3">
              <ClientOnly>
                <GoogleSignInButton button-text="Sign in with Google" />
                <MicrosoftSignInButton button-text="Sign in with Microsoft" />
              </ClientOnly>
            </div>
          </div>
        </div>
        
        <div class="mt-12 sm:mt-16 lg:mt-20" v-else>
          <p class="text-wobby-purple-800 mb-3 sm:mb-4 text-2xl sm:text-3xl font-fakt-blond">Hi there! </p>
          <p class="text-gray-700 text-lg sm:text-xl font-fakt-blond">We are currently in closed beta as we work
            to improve the app. <span>If you'd like to know more, please <a href="https://wobby.ai"
                  target="_blank" class="underline text-wobby-gray-900">contact
                  us</a>. </span></p>
          <p class="text-gray-700 mt-4 sm:mt-5 text-lg sm:text-xl font-fakt-blond"> We look forward to welcoming users soon again.
          </p>
        </div>
      </div>
    </div>

    <!-- Right side (image) -->
    <div class="relative hidden w-0 flex-1 lg:block">
      <img class="absolute inset-0 h-full w-full object-cover fade-in-image"
        src="/assets/images/wobby-darya-walking.jpg" alt="" />
    </div>
    
    <ForgotPasswordDialog v-model="showForgotPassword" />
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import GoogleSignInButton from '@/components/buttons/GoogleSignInButton.vue';
import MicrosoftSignInButton from '@/components/buttons/MicrosoftSignInButton.vue';
import ForgotPasswordDialog from '~/components/dialogs/ForgotPasswordDialog.vue';


const identityStore = useIdentityStore();
const { login } = identityStore
const { user } = storeToRefs(identityStore)

const toast = useToast();



const route = useRoute()
const router = useRouter();
const error = ref('');
const loading = ref(false)
let showForgotPassword = ref(false);

definePageMeta({
    auth: false,
    layout: 'blank',
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
})

useHead({
    title: 'Sign in | Wobby'
})

if (route.query.error) {
    if (route.query.error === 'oauth-failed-login') {
        error.value = "We couldn't log you in. Please try again.";
    } else {
        error.value = route.query.error as string;
    }

}

const loginForm = reactive({
    email: '',
    password: '',
    keepSignedIn: true,
})

const showSignIn = ref(true);

if (route.query.code && route.query.code === 'innovation') {
    showSignIn.value = true;
}

const submit = async () => {
    if (loginForm.email === '' || loginForm.password === '') {
        error.value = "Please fill in all fields.";
        return;
    }

    try {
        loading.value = true;

        await login(loginForm.email, loginForm.password);
        useTrackEvent('login', { method: 'email', success: true, email: loginForm.email })
        router.push('/');

        toast.add({
            title: 'Logged in',
            description: 'You are now logged in',
        })

    } catch (e: any) {
        error.value = `These credentials don't look right. Please try again.`;

        useTrackEvent('login_failed', { method: 'email', success: false, email: loginForm.email })
        toast.add({
            color: 'red',
            title: 'Login Failed',
            description: "That didn't work",
        })
        loading.value = false;
    }
}

</script>